import React, { useState } from 'react'
import './custom-modal.scss'
// import './basic-details-modal/basic-details-modal.scss'
import Modal from "react-modal";
import modalClose from '../../assets/images/modal/modalClose.svg'
import UserImage from "../../assets/images/landing-page/profile-image.jpg";
import staricon from "../../assets/images/landing-page/star1.png";
import GoogleIcon from "../../assets/images/landing-page/googleicon.svg"
const CustomModal = ({ modalOpen, setModalOpen, handleDeleteCart, bookPractical, type, modalBody, className, portalClassName }) => {


    const closeModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <Modal
                portalClassName={`ReactModalPortal ` + portalClassName}
                className={(className ? className : "") + " " + (bookPractical == 'bookPractical' ? "custom-modal bookPractical-main-div" : "custom-modal ")}
                isOpen={modalOpen}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => {
                    closeModal();
                }}
                closeTimeoutMS={200}
                ariaHideApp={false}

            >
                <div className={bookPractical == 'bookPractical' ? 'modal-body-container' : 'modal-body-container container'}>
                    <div className={bookPractical == 'bookPractical' ? "modal-header-div book-practical-header" : "modal-header-div"}>
                        <h5 className='modal-title'>

                        </h5>
                        <button className='modal-close-icon' onClick={() => closeModal()}>
                            <img
                                src={modalClose}
                                alt="modalClose"
                            />
                        </button>
                    </div>
                    {
                        type == "review" || type == "treatmentDetails" || type == "calendar" || type == 'common-width' ?
                            modalBody
                            :


                            <div className="dynamic-content">
                                <p className='dynamic-content-text'>Are you sure you want to delete item from the cart?</p>

                                <div className='d-flex justify-content-center mt-1'>
                                    <button className='dynamic-content-btn' onClick={() => handleDeleteCart()}>YES</button>
                                    <button className='dynamic-content-outline ms-3' onClick={() => setModalOpen(false)}>No</button>
                                </div>
                            </div>
                    }
                </div>
            </Modal >
        </>
    )
}

export default CustomModal